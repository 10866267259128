@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --page-background: 255, 255, 255;
  --sidebar-background: 249, 250, 251; /* gray-50 */
  --input-background: 249, 250, 251; /* gray-50 */
  --green-background: 209, 250, 229; /* emerald-100 */
  --red-background: 255, 237, 213; /* orange-100 */
  --blue-background: 200, 230, 250;

  --pop-text: 0, 0, 0; /* black */
  --standard-text: 17, 24, 39; /* gray-900 */
  --secondary-text: 75, 85, 99; /* gray-600 */
  --tertiary-text: 156, 163, 175; /* gray-400 */
  --disabled-text: 209, 213, 219; /* gray-300 */
  --disabled-text-hover: 156, 163, 175; /* gray-400 */
  --placeholder-text: 156, 163, 175; /* gray-400 */
  --colored-background-text: 17, 24, 39; /* gray-900 */
  --blue-text: 79, 70, 229; /* indigo-600 */
  --red-text: 220, 38, 38; /* red-600 */
  --green-text: 34, 197, 94; /* green-500 */

  --list-item-current: 229, 231, 235; /* gray-200 */
  --list-item-hover: 243, 244, 246; /* gray-100 */

  --standard-ring: 209, 213, 219; /* gray-300 */
  --secondary-ring: 229, 231, 235; /* gray-200 */
  --focused-ring: 79, 70, 229; /* indigo-600 */

  --button-hover: 249, 250, 251; /* same as input background */
  --button-disabled: 249, 250, 251; /* same as button hover */
}

@media (prefers-color-scheme: dark) {
  :root {
    --page-background: 33, 33, 33; /* chatgpt/vscode style */ /*IMPORTANT: update global viewport themeColor when changing this*/
    --sidebar-background: 23, 23, 23; /* chatgpt/vscode style */
    --input-background: 49, 49, 49; /* chatgpt/vscode style */
    --green-background: 16, 185, 129; /* emerald-500 */
    --red-background: 206, 145, 120; /* vscode red color */
    --blue-background: 100, 150, 200;

    --pop-text: 255, 255, 255; /* white */
    --standard-text: 209, 213, 219; /* gray-300, same as prose-invert styles */
    --secondary-text: 156, 163, 175; /* gray-400 */
    --tertiary-text: 107, 114, 128; /* gray-500 */
    --disabled-text: 75, 85, 99; /* gray-600 */
    --disabled-text-hover: 55, 65, 81; /* gray-700 */
    --placeholder-text: 107, 114, 128; /* gray-500 */
    --colored-background-text: 0, 0, 0; /* black */
    --blue-text: 129, 140, 248; /* indigo-400 */
    --red-text: 206, 145, 120; /* vscode red color */
    --green-text: 16, 185, 129; /* emerald-500 */

    --list-item-current: 45, 45, 45; /* */
    --list-item-hover: 38, 38, 38; /* close to vscode's 33 */

    --standard-ring: 75, 75, 75; /* close to vscode's 60 */
    --secondary-ring: 45, 45, 45; /* */
    --focused-ring: 129, 140, 248; /* indigo-400 */

    --button-hover: 49, 49, 49; /* same as input background */
    --button-disabled: 49, 49, 49; /* same as button hover */
  }
}

div.prose img {
  display: inline;
  vertical-align: bottom;
  margin: 0;
  padding: 0;
}


/* special case text formatting for classes in courtlistener's data */
.star-pagination {
  display: block;
  color: rgba(var(--tertiary-text));
  margin-top: 1rem;
}
/* some cases have "* [number]" pagination, some "* Page [number]", it's unclear whether to add "Page" prefix or not */
/* .star-pagination::before {
  content: "Page ";
} */
